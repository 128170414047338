// Library imports
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const Header = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Box sx={{ maxWidth: "100%" }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                    <Typography display="inline" variant="h1">
                        <Typography display="inline" variant="h2" component="span" color="primary.main" fontWeight="bold">
                            {t("header_section_1")}
                        </Typography>
                        <Typography display="inline" variant="h2" component="span" color="secondary.main" fontWeight="bold">
                            {t("header_section_2")}
                        </Typography>
                    </Typography>
                </Link>
            </Box>
            <Typography variant="subtitle2" color="secondary.main">
                {t("header_sub")}
            </Typography>
        </React.Fragment>
    );
}

export default Header;