// Library imports
import { Button, Typography, useTheme, Menu, Link } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

type RenderPageItemProps = PageMenuItemProps & {
    onClick?: () => void;
}

const RenderPageItem = ({ page, onClick }: RenderPageItemProps) => {
    const onClickInternal = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <Button id={page.id}>
            <Typography color="secondary.contrastText" variant="button">
                <Link to={page.link!} component={RouterLink} color="secondary.contrastText" underline="hover" onClick={onClickInternal}>{page.text}</Link>
            </Typography>
        </Button>
    )
}

const PageMenuItems = ({ page }: PageMenuItemProps) => {
    const { palette } = useTheme();
    const navigationBarItem = {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText
    }

    return page.subitems ? (
        <PopupState variant="popover" popupId={page.id}>
            {(popupState) => (
                <React.Fragment>
                    <Button {...bindTrigger(popupState)}>
                        <Typography color="secondary.contrastText" variant="button">
                            <Link color="secondary.contrastText" underline="hover">{page.text}</Link>
                        </Typography>
                    </Button>
                    <Menu {...bindMenu(popupState)} PaperProps={{ sx: navigationBarItem }}>
                        {page.subitems?.map((item: Page) => <RenderPageItem key={item.id} page={item} onClick={popupState.close} />)}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    ) : (
        <RenderPageItem key={page.id} page={page} />
    );
}

const MenuLarge = ({ pages, onSearchTermChangedCallback }: MenuProps) => {
    return (
        <React.Fragment>
            {pages.map((page) => <PageMenuItems key={page.id} page={page} />)}
        </React.Fragment>
    );
}

export default MenuLarge;