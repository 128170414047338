// Library imports
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { AppBar, Container, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import MenuSmall from "./menu-small";
import MenuLarge from "./menu-large";

const getPages = (t: TFunction) => {
    const pages: Page[] = [{
        id: "menuCativyWallPrevention",
        text: t("cavityWallPrevention"),
        link: "test",
        subitems: [{
            id: "menuTest",
            text: "Test",
            link: "test"
        }]
    }, {
        id: "menuBirdPrevention",
        text: t("birdPrevention"),
        link: "test"
    }, {
        id: "menuWeaselPrevention",
        text: t("weaselPrevention"),
        link: "test"
    }, {
        id: "menuRodenControl",
        text: t("rodenControl"),
        link: "test"
    }, {
        id: "menuArchitecturalPrevention",
        text: t("architecturalPrevention"),
        link: "test"
    }];

    return pages;
}


const Menu = () => {
    const { t } = useTranslation("menu");
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.between("xs", "md"));
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const onSearchChange = () => {

    }

    const pages = getPages(t);

    return (
        <AppBar position="relative" color="secondary">
            <Container>
                <Toolbar disableGutters>
                    {isSmall ? (
                        <MenuSmall pages={pages} onSearchTermChangedCallback={onSearchChange} />
                    ) : (
                        <MenuLarge pages={pages} onSearchTermChangedCallback={onSearchChange} />
                    )}

                </Toolbar>
            </Container >
        </AppBar >
    )
}

export default Menu;