// Library imports
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText, SxProps } from "@mui/material";

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
    sx?: SxProps;
}

const ListItemLink = ({ icon, primary, to, sx }: ListItemLinkProps) => {
    const renderLink = React.useMemo(() =>
        React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(function Link(itemProps, ref) {
            return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
        }),
        [to],
    );

    return (
        <li>
            <ListItem button component={renderLink} sx={sx}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

export default ListItemLink;