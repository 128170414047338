// Library imports
import { TFunction } from "react-i18next";

export interface FooterColumn {
    title: string;
    items: FooterColumnItem[];
}

export interface FooterColumnItem {
    name: string;
    link?: string;
}

export interface PageItems {
    customerService: FooterColumn;
    business: FooterColumn;
    information: FooterColumn;
    aboutUs: FooterColumn;
}

const getPageItems = (t: TFunction) => {
    return {
        customerService: {
            title: t("footerTitleCustomerService"),
            items: [{
                name: "voorbeeld 1",
                link: "test"
            }, {
                name: "voorbeeld 2"
            }, {
                name: "voorbeeld 3"
            }, {
                name: "voorbeeld 4"
            }, {
                name: "voorbeeld 5"
            }]
        },
        business: {
            title: t("footerTitleBusiness"),
            items: [{
                name: "voorbeeld 1",
                link: "test"
            }, {
                name: "voorbeeld 2"
            }, {
                name: "voorbeeld 3"
            }, {
                name: "voorbeeld 4"
            }, {
                name: "voorbeeld 5"
            }]
        },
        information: {
            title: t("footerTitleInformation"),
            items: [{
                name: "voorbeeld 1",
                link: "test"
            }, {
                name: "voorbeeld 2"
            }, {
                name: "voorbeeld 3"
            }, {
                name: "voorbeeld 4"
            }, {
                name: "voorbeeld 5"
            }]
        },
        aboutUs: {
            title: t("footerTitleAboutUs"),
            items: [{
                name: "voorbeeld 1",
                link: "test"
            }, {
                name: "voorbeeld 2"
            }, {
                name: "voorbeeld 3"
            }, {
                name: "voorbeeld 4"
            }, {
                name: "voorbeeld 5"
            }]
        }
    }
};

export default getPageItems;