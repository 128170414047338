// Library imports
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Link, Container, Box, useTheme, Grid, Stack, Typography, Button, styled } from "@mui/material";

// Project imports
import getPageItems, { FooterColumn } from "./links";

const HorizontalRule = styled("hr")(({ }) => `
    text-align: center;
    width: 50%;
`);

interface RenderFooterColumnProps {
    column: FooterColumn;
}

const RenderFooterColumn = ({ column }: RenderFooterColumnProps) => {
    const theme = useTheme();

    return (
        <Stack
            direction="column"
            alignItems="center">
            <Typography variant="h5" color="secondary.contrastText">{column.title}</Typography>
            <HorizontalRule />
            {column.items.map((item, idx) => {
                return item.link ? (
                    <Button key={idx} id={item.name} size="small">
                        <Typography color="secondary.contrastText" variant="button">
                            <Link to={item.link} component={RouterLink} color="secondary.contrastText" underline="hover">{item.name}</Link>
                        </Typography>
                    </Button>
                ) : (
                    <Typography color="secondary.contrastText" variant="button">{item.name}</Typography>
                );
            })}
        </Stack>
    );
}

const Footer = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const pageItems = getPageItems(t);

    return (
        <Box bgcolor="secondary.main">
            <Box height={theme.spacing(3)} />
            <Box bgcolor="primary.light">
                <Container sx={{ paddingY: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                            <RenderFooterColumn column={pageItems.customerService} />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <RenderFooterColumn column={pageItems.business} />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <RenderFooterColumn column={pageItems.information} />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <RenderFooterColumn column={pageItems.aboutUs} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <Container sx={{ paddingY: 3, display: "flex", justifyContent: "end" }}>
                    <Box>
                        <Typography variant="button" color="secondary.contrastText">
                            Altijd veilig &amp; snel betalen
                        </Typography>
                    </Box>
                    <Box>

                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default Footer;