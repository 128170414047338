import { Search } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";

const SearchBar = () => {

    return (
        <Box>
            <Search />
            <TextField placeholder="Search..."></TextField>
        </Box>
    );

}

export default SearchBar;