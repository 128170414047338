// Library imports
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from "@mui/material";

// Css import
import "./App.css";

// Page imports
import Layout from "./components/Layout";
import Home from "./pages/Home";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import PageNotFound from "./pages/errors/PageNotFound";
import { ContactInformation } from "./api/models/ContactInformation";

const AppBase = () => {
  const { t } = useTranslation();

  let theme = createTheme({
    palette: {
      primary: {
        main: "#5171b7"
      },
      secondary: {
        main: "#626365"
      }
    }
  });
  theme = responsiveFontSizes(theme);

  const contactInformation: ContactInformation = {
    phoneNumber: "+31 (0)475-123456",
    emailAddress: "info@weringswinkel.nl"
  }

  return (
    <HelmetProvider>
      <CssBaseline />
      <Helmet titleTemplate={"%s | " + t("title")} defaultTitle={t("title")} />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home contactInformation={contactInformation} />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

const Loader = () => (
  <div>loading...</div>
);

const App = () => {
  return (
    <div id="app">
      <Suspense fallback={<Loader />}>
        <AppBase />
      </Suspense>
    </div >
  );
}

export default App;
