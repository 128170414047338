// Library imports
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Container, Grid, Link, Stack, Typography } from "@mui/material";
import { Email, PhoneAndroid } from "@mui/icons-material";
import parsePhoneNumber from "libphonenumber-js";

// Project imports
import HouseSelector, { HouseCanvasPoint } from "./house-selector";
import { ContactInformation } from "../../api/models/ContactInformation";

export interface HomeProps {
    contactInformation: ContactInformation;
}

const Home = ({ contactInformation }: HomeProps) => {
    // TODO: Fetch from API
    const points: HouseCanvasPoint[] = [{
        name: "Goot",
        description: "In de goot zitten vaak vogels, hoe gaat u daar mee om?",
        actionText: "Production bekijken",
        actionUrl: "test",
        x: 8,
        y: 33,
        size: 40
    }];

    const assortment = [
        {
            name: "Scheur in de muur",
            image: "https://www.wonen.nl/images/665579982/Scheur_muren/ontstaan-scheuren-muren.jpg"
        },
        {
            name: "Muizen overlast",
            image: "https://milistoru8prod.blob.core.windows.net/cache/9/b/a/2/0/4/9ba2042aab68c74bb704884f5d1bcaf7ffd5af1a.jpg"
        },
        {
            name: "Deuropeningen",
            image: "https://geluidsdichtmaken.nl/wp-content/uploads/2020/04/deur-geluidsdicht-maken.jpg"
        },
        {
            name: "Vogel overlast",
            image: "https://www.denpgevelonderhoud.nl/uploads/Headers/dove-3241720_960_720.jpg"
        },
        {
            name: "Marter overlast",
            image: "https://ecopedia.s3.eu-central-1.amazonaws.com/styles/colorbox-groot/sa/media/2/2340-master.jpg?itok=Plvk7xiG"
        },
        {
            name: "Open spouw",
            image: "https://www.isolatie-info.nl/wp-content/uploads/heb-ik-een-spouwmuur.jpg"
        }
    ];

    const mostSold = [
        {
            name: "SpouwSafe",
            image: "https://ongedierteproducten.nl/wp-content/uploads/2019/11/full.jpg"
        },
        {
            name: "NetNox",
            image: "https://www.ongediertebestrijdenshop.nl/wp-content/uploads/2020/01/netnox_15_cm.jpg"
        },
        {
            name: "MouseShield",
            image: "https://media.s-bol.com/JGn88LNgrXl/1200x1200.jpg"
        },
        {
            name: "Raxit Door Seal Rol",
            image: "https://catalogus.killgerm.nl/pub/media/catalog/product/cache/132bf924fb474880b8afdc873e715875/r/a/raxit-doorseal-rol.jpg"
        }
    ];

    const { t } = useTranslation();

    const phoneNumber = parsePhoneNumber(contactInformation.phoneNumber, "NL");

    return (
        <React.Fragment>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Container>
                <Grid container spacing={5}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <CardHeader title={t("homeHouseSelectorTitle")}
                                    titleTypographyProps={{ color: "primary.main", textAlign: "center", fontWeight: "bold" }}
                                    subheader={t("homeHouseSelectorSubTitle")}
                                    subheaderTypographyProps={{ textAlign: "center" }} />
                                <CardMedia>
                                    <HouseSelector points={points}></HouseSelector>
                                </CardMedia>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <Stack
                                    direction="column"
                                    alignItems="center"
                                    height="100%">
                                    <Box width="100%">
                                        <CardHeader title="Hulp nodig?"
                                            titleTypographyProps={{ color: "primary.main", textAlign: "center", fontWeight: "bold" }}
                                            subheader="We helpen je graag!"
                                            subheaderTypographyProps={{ textAlign: "center" }} />
                                    </Box>
                                    <Box width="50%" flexGrow={1}>
                                        <CardMedia
                                            component="img"
                                            image="/images/dummy_question.png"
                                            alt="Person with questionmark"
                                        />
                                    </Box>
                                    <Box width="100%">
                                        <CardContent>
                                            <Button variant="contained" fullWidth>Veelgestelde vragen</Button>
                                        </CardContent>
                                    </Box>
                                    <Box bgcolor="primary.main" width="100%">
                                        <CardContent>
                                            <Typography variant="button" color="primary.contrastText" display="flex" justifyItems="center" py={0.5}>
                                                Of neem contact met ons op!
                                            </Typography>
                                            <Typography variant="button" color="primary.contrastText" display="flex" justifyItems="center" py={0.5}>
                                                <PhoneAndroid />
                                                <Link pl={1} color="inherit" underline="hover" href={`tel:${phoneNumber?.number}`}>{phoneNumber?.formatInternational()}</Link>
                                            </Typography>
                                            <Typography variant="button" color="primary.contrastText" display="flex" justifyItems="center" pr={1} py={0.5}>
                                                <Email />
                                                <Link pl={1} color="inherit" underline="hover" href={`mailto:${contactInformation.emailAddress}`}>{contactInformation.emailAddress}</Link>
                                            </Typography>
                                            <Typography variant="caption" color="primary.contrastText" display="flex" justifyItems="center" pr={1} py={0.5}>
                                                Openingstijden ma t/m vr 8:30 - 17:00 u
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" color="secondary.main" align="center">
                                Bekijk hier ons gehele assortiment
                            </Typography>
                        </Grid>
                        {assortment.map((item, idx) => {
                            return (
                                <Grid key={idx} item md={4} xs={12}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardActionArea sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <CardMedia
                                                component="img"
                                                image={item.image}
                                                alt={item.name}
                                            />
                                            <CardContent>
                                                <Typography variant="h6">{item.name}</Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" color="secondary.main" align="center">
                                Meest verkochte producten
                            </Typography>
                        </Grid>
                        {mostSold.map((item, idx) => {
                            return (
                                <Grid key={idx} item md={3} xs={12}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardActionArea sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <CardMedia
                                                component="img"
                                                image={item.image}
                                                alt={item.name}
                                            />
                                            <CardContent>
                                                <Typography variant="h6">{item.name}</Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment >
    );
}

export default Home;
