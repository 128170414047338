// Library imports
import React from "react";
import { Helmet } from "react-helmet-async";

const PageNotFound = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <p>Sorry, but this page does not exist</p>
        </React.Fragment>
    );
}

export default PageNotFound;
