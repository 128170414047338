// Library imports
import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PrismaZoom from "react-prismazoom";
import { Button, Card, CardActions, CardContent, IconButton, Link, Popover, Typography, useTheme } from "@mui/material";
import { QuestionMarkRounded } from "@mui/icons-material";

// Project imports
import { clamp } from "../../utils/math-extensions";

export interface HouseCanvasPoint {
    name: string;
    description: string;
    actionText: string;
    actionUrl: string;
    x: number;
    y: number;
    size: number;
}

interface HouseSelectorPointProps {
    point: HouseCanvasPoint;
    onClickPoint?: () => void;
}

interface HouseCanvasProps {
    points: HouseCanvasPoint[];
    onCanvasPointChanged?: (point: HouseCanvasPoint | null) => void;
}

const HouseSelectorPoint = ({ point, onClickPoint }: HouseSelectorPointProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (onClickPoint) {
            //onClickPoint();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "popover-" + point.name.toLowerCase() : undefined;

    return (
        <React.Fragment>
            <IconButton
                aria-describedby={id}
                style={{
                    backgroundColor: theme.palette.primary.main,
                    position: "absolute",
                    width: point.size,
                    left: clamp(point.x, 0, 100) + "%",
                    top: clamp(point.y, 0, 100) + "%"
                }}
                onClick={handleClick}>
                <QuestionMarkRounded style={{ color: "white" }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h6">{point.name}</Typography>
                        <Typography variant="body2">{point.description}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="primary">
                            <Link to={point.actionUrl} component={RouterLink} underline="hover">{point.actionText}</Link>
                        </Button>
                    </CardActions>
                </Card>
            </Popover>
        </React.Fragment>
    );
}

const HouseSelector = ({ points, onCanvasPointChanged }: HouseCanvasProps) => {
    const prismaZoomApi = useRef<any>(null);

    const zoomToPoint = (point: HouseCanvasPoint) => {
        if (!prismaZoomApi.current) { return; }
        prismaZoomApi.current.zoomToZone(point.x, point.y, point.size, point.size);
    }

    return (
        <React.Fragment>
            <div style={{ position: "relative", overflow: "hidden", backgroundColor: "#F0F1EB" }}>
                <PrismaZoom ref={prismaZoomApi} maxZoom={1.5} allowTouchEvents>
                    <img src="/images/house_cutout.jpg" style={{ width: "100%" }} />
                    {points.map((point, idx) => <HouseSelectorPoint key={idx} point={point} onClickPoint={() => zoomToPoint(point)} />)}
                </PrismaZoom>
            </div>
        </React.Fragment>
    );
}

export default HouseSelector;