// Library imports
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Typography } from "@mui/material";
import i18n from "../../i18n";
import { Check } from "@mui/icons-material";

// Component imports

const Banner = () => {
    const { t } = useTranslation();

    //TODO: Hardcoded values for the banner will later one have to be fetched from the API:
    // - Delivery time
    // - Currency type
    // - Free delivery amount
    return (
        <React.Fragment>
            <Box sx={{ backgroundColor: "primary.main" }}>
                <Container>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", p: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1.5 }}>
                            <Check sx={{ color: "orange", mr: 1 }} />
                            <Typography variant="caption" color="secondary.contrastText">
                                {t("bannerDeliveryTime", { count: 3 })}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1.5 }}>
                            <Check sx={{ color: "orange", mr: 1 }} />
                            <Typography variant="caption" color="secondary.contrastText">
                                {t("bannerProfessiontalEquipment")}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1.5 }}>
                            <Check sx={{ color: "orange", mr: 1 }} />
                            <Typography variant="caption" color="secondary.contrastText">
                                {t("bannerFreeDelivery", {
                                    amount: 75,
                                    formatParams: {
                                        amount: { currency: "EUR", locale: i18n.language }
                                    }
                                })}
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
}

export default Banner;
