// Library imports
import { Box, Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router";

// Component imports
import Header from "./core/Header";
import Banner from "./core/Banner";
import Menu from "./core/Menu";
import Footer from "./core/Footer";
import { styled } from "@mui/system";

const HeaderWrapper = styled("header")(({ }) => `
    flex-grow: 0
`);

const MainWrapper = styled("main")(({ }) => `
    flex-grow: 1;
`);

const FooterWrapper = styled("footer")(({ }) => `
    flex-grow: 0
`);

const Layout = () => {
    return (
        <Box minHeight="100vh" display="flex" flexDirection="column">
            <HeaderWrapper id="header">
                <Container sx={{ paddingY: 3 }}>
                    <Header />
                </Container>
                <Box>
                    <Banner />
                </Box>
                <Menu />
            </HeaderWrapper>
            <MainWrapper id="content">
                <Container sx={{ paddingY: 3 }}>
                    <Outlet />
                </Container>
            </MainWrapper>
            <FooterWrapper id="footer">
                <Footer />
            </FooterWrapper>
        </Box>
    );
}

export default Layout;
