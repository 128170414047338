// Library imports
import React, { useState } from "react";
import { Box, IconButton, Drawer, List, Collapse, ListItemButton, ListItemText, ListItemIcon, Divider, TextField } from "@mui/material";
import { ExpandLess, ExpandMore, Menu as MenuIcon } from "@mui/icons-material";
import ListItemLink from "../../util/ListItemLink";
import SearchBar from "../../forms/SearchBar";

const PageMenuItems = ({ page, selected }: PageMenuItemProps) => {
    const [open, setOpen] = useState(selected ?? false);

    const handleClick = () => {
        setOpen(!open);
    };

    return page.subitems ? (
        <React.Fragment>
            <ListItemButton
                onClick={handleClick}
                id={page.id}>
                {page.icon ? <ListItemIcon>{page.icon}</ListItemIcon> : ""}
                <ListItemText primary={page.text} sx={{ marginRight: 2 }} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {page.subitems.map((item) =>
                        <ListItemLink
                            key={page.id + "_" + item.id}
                            to={item.link!}
                            primary={item.text}
                            sx={{ pl: 4 }}
                            icon={item.icon} />
                    )}
                </List>
            </Collapse>
        </React.Fragment>
    ) : (
        <ListItemLink
            to={page.link!}
            primary={page.text}
            icon={page.icon} />
    );
}

const MenuSmall = ({ pages, onSearchTermChangedCallback }: MenuProps) => {
    const [showDrawer, setShowDrawer] = useState(false);

    const openDrawer = () => {
        setShowDrawer(true);
    };

    const closeDrawer = () => {
        setShowDrawer(false);
    };

    return (
        <Box sx={{ width: "auto" }}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={openDrawer}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="left"
                open={showDrawer}
                onClose={closeDrawer}
                disableScrollLock={true}>
                <Box
                    role="presentation">
                    <List>
                        {pages.map((page) => <PageMenuItems key={page.id} page={page} />)}
                    </List>
                    <Divider />
                    <SearchBar />
                    {/* <TextField
                        variant="outlined"
                        label="Search"
                        placeholder="Search for a product..."
                        sx={{ m: 2, borderRadius: "shape.borderRadius" }}
                        onChange={(ev) => onSearchTermChangedCallback(ev.target.value)} /> */}
                </Box>
            </Drawer >
        </Box >
    )
}

export default MenuSmall;